import React, { useState, useRef } from 'react';
import { Carousel, Button } from 'antd';
import LazyLoad from 'react-lazyload';
import Container from './Container';
// logos
import feifanCar from '../images/feifanCar.svg'
import Decathlon from '../images/decathlon.svg';
import lvmh from '../images/lvmh.svg'
import UNIQLO from '../images/uniqlo.svg'
import Richemont from '../images/Richemont.svg'
import BVLGARI from '../images/bvlgari.svg'
import DFS from '../images/dfs.svg'
import adidas from '../images/adidas.svg'
import Fonterra from '../images/fonterra.svg'
import CocaCola from '../images/cocacola.svg'
import DQ from '../images/DQ.svg'
import Chiaus from '../images/chiaus.svg'
import VORWERK from '../images/vorwerk.svg'
import huarun from '../images/huarun.svg'
import stanley from '../images/stanley-black-decker.svg'
import ASUS from '../images/asus.svg'
import SAICGeneralMotors from '../images/SAICGeneralMotors.svg'
import theEconomist from '../images/the-economist.svg'
import ClubMed from '../images/club-med.svg'
import efEducation from '../images/ef-education.svg'
import shuijingfang from '../images/shuijingfang.svg'
import spdBank from '../images/spd-bank.svg'
import ddi50 from '../images/ddi50.svg'
import financial from '../images/financial-3.svg'
import Kering from '../images/Kering.svg'
import SpdBank from '../images/spd-bank.svg';
import diyicaijin from '../images/diyicaijin.svg'

const CAROUSEL_DATA = [
  {
    logoName: 'SPD Bank',
    logoSrc: SpdBank,
    content:
      '面对着海量用户但活跃度偏低，运营人员数量有限，精细化运营无法有效达成等挑战，浦发银行基于现有数据管理平台，通过DM Hub 产品搭建互联网营销运营平台，实现精准高效的客户互动和营销活动参与率的大幅提升，形成数据驱动的自动化精准营销能力。',
  },
  {
    logoName: '迪卡侬',
    logoSrc: Decathlon,
    content:
      '新的营销自动化系统从操作上来说更简单易用，并且支持丰富的沟通触点以及条件判断组件，可以轻松的满足复杂的业务需求，使得CRM团队在执行会员生命周期的精准化沟通更加的高效。MAP工具上线短短几个月的时间已经帮助我们搭建了许多自动化的沟通场景，除了解决手动操作的繁琐流程还增加触达的及时性。另外，灵活的分析看板也使得流程中的沟通效果可以被实时的监测，协助我们优化沟通策略，创造更好的用户体验。'
  },
  {
    logoName: '非凡汽车',
    logoSrc: feifanCar,
    content:
      '在MA平台，用户任何状态更新、或行为互动事件，皆能运用MA简单配置出自动化旅程，完成实时且多渠道触达。平台集成的多样组件及内建的各触点工具，让运营人员可独立操作完成场景上线，提高运营效率。还可实时监控活动效果，随时优化迭代，调整策略，形成业务闭环。除此之外，它还提供了快捷的数据定义、数据接入能力，实现企业内部用户数据及标签数据接入及集成，形成用户360画像视图。同时，基于用户属性、行为、标签的组合查询能力，也方便易用。'
  },
];

const CAROUSEL_LENGTH = CAROUSEL_DATA.length;

const LOGO_DATA = [
  { logoIndex: 6, logoName: 'Kering', logoSrc: Kering },//开云
  { logoIndex: 4, logoName: 'lvmh', logoSrc: lvmh },//lv
  { logoIndex: 23, logoName: 'UNIQLO', logoSrc: UNIQLO },
  { logoIndex: 15, logoName: 'Richemont', logoSrc: Richemont },//历峰
  { logoIndex: 1, logoName: 'adidas', logoSrc: adidas },
  { logoIndex: 5, logoName: 'BVLGARI', logoSrc: BVLGARI },
  { logoIndex: 11, logoName: 'DFS', logoSrc: DFS },
  { logoIndex: 13, logoName: 'Fonterra', logoSrc: Fonterra },
  { logoIndex: 17, logoName: 'Coca-Cola', logoSrc: CocaCola },
  { logoIndex: 14, logoName: '华润', logoSrc: huarun },
  { logoIndex: 21, logoName: 'decathlon', logoSrc: Decathlon },//迪卡侬
  { logoIndex: 25, logoName: 'StanleyBlack&Decker', logoSrc: stanley },
  { logoIndex: 2, logoName: 'ASUS', logoSrc: ASUS },
  { logoIndex: 3, logoName: 'SAICGeneralMotors', logoSrc: SAICGeneralMotors },//上汽通用
  { logoIndex: 16, logoName: '水井坊', logoSrc: shuijingfang },
  { logoIndex: 24, logoName: 'VORWERK', logoSrc: VORWERK },
  { logoIndex: 12, logoName: 'EF教育', logoSrc: efEducation },
  { logoIndex: 7, logoName: 'Club Med', logoSrc: ClubMed },
  { logoIndex: 22, logoName: 'The Economist', logoSrc: theEconomist },//经济学人
  { logoIndex: 20, logoName: '第1财经', logoSrc: diyicaijin },//第一财经
  { logoIndex: 19, logoName: 'Chiaus', logoSrc: Chiaus },//雀氏
  { logoIndex: 10, logoName: 'feifanCar', logoSrc: feifanCar, logoSrc: feifanCar },// 非凡汽车
  { logoIndex: 9, logoName: 'Spd Bank', logoSrc: spdBank },//浦发银行
  { logoIndex: 18, logoName: 'DDI 50', logoSrc: ddi50 },
  { logoIndex: 8, logoName: 'DQ', logoSrc: DQ },
];

export default function HomeLogos() {
  const carouselRef = useRef();
  const [carouselIndex, setCarouselIndex] = useState(0);

  // 走马灯按钮：向左
  function onCarouselGoLeft() {
    setCarouselIndex(carouselIndex - 1 < 0 ? CAROUSEL_LENGTH - 1 : carouselIndex - 1);
    carouselRef.current.prev();
  }

  // 走马灯按钮：向右
  function onCarouselGoRight() {
    setCarouselIndex(carouselIndex + 1 > CAROUSEL_LENGTH - 1 ? 0 : carouselIndex + 1);
    carouselRef.current.next();
  }

  return (
    <section id="logos" className="home-logos">
      <Container fluid="xl">
        <h3>400+顶尖企业的共同选择</h3>
        <div className="carousel-with-btns">
          <Carousel autoplay ref={carouselRef} effect="fade">
            {CAROUSEL_DATA.map((carouselItem) => (
              <div className="carousel-content-wrapper" key={carouselItem.logoName}>
                <div className="carousel-content">
                  <img
                    className="carousel-logo"
                    src={carouselItem.logoSrc}
                    alt={carouselItem.logoName}
                  />
                  <span className="iconfont font-24 icondouble-quot-left quot-left" />
                  {/* <img src={DoubleQuotLeft} className="quot-left" alt="quot" /> */}
                  <h3>{carouselItem.content}</h3>
                  <span className="iconfont font-24 icondouble-quot-right quot-right" />
                  {/* <img src={DoubleQuotRight} className="quot-right" alt="quot" /> */}
                  <div className="clear" />
                </div>
              </div>
            ))}
          </Carousel>
          <Button onClick={onCarouselGoLeft} className="carousel-btn left">
            {/* <img src={LessThan} alt="lt" /> */}
            <span className="iconfont font-24 iconless-than" />
          </Button>
          <Button onClick={onCarouselGoRight} className="carousel-btn right">
            {/* <img src={GreaterThan} alt="gt" /> */}
            <span className="iconfont font-24 icongreater-than" />
          </Button>
        </div>
      </Container>
      <Container>
        {/* <Decathlon /> */}
        <LazyLoad>
          <div className="logos">
            {LOGO_DATA.map((logoItem) => {
              const { logoIndex } = logoItem;
              const columnIndex = Math.ceil(logoIndex / 10) - 1;
              const rowIndex = logoIndex - columnIndex * 10;
              const positionX = columnIndex * 500;
              const positionY = (rowIndex - 1) * 150;
              const xStr = positionX ? `-${positionX}px` : '0px';
              const yStr = positionY ? `-${positionY}px` : '0px';
              return (
                <div className="logo" key={logoItem.logoName}>
                  {/* <div className="logo-image" style={{ backgroundPosition: `${xStr} ${yStr}` }} /> */}
                  <img className="logo-image" src={logoItem.logoSrc}></img>
                  {/* <div style={{border:`1px solid red`}}>test</div> */}
                </div>
              );
            })}
          </div>
        </LazyLoad>
      </Container>
    </section>
  );
}
